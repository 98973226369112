import axios from 'axios'
import { injectable, inject, postConstruct } from 'inversify'
import type { CVISupportedChainIds } from '../types'
import { isHardhatChainId } from '../types'
import { getHardhatAPIURL } from '../get-single-deployments-file'

@injectable()
export class GetForkNumberInversifyService {
  public hardhatNodeForkBlockNumber?: number

  constructor(@inject('ChainId') public readonly chainId: CVISupportedChainIds) {}
  @postConstruct()
  public async init() {
    if (isHardhatChainId(this.chainId)) {
      const r = await axios.get<{
        description: string
        dateTimeNowUtc: string
        forkTimeUtc: string
        forkBlockNumber: number
        podRunningMinutes: number
        startServerTimeUtc: string
        hardhatPreparationTimeMinutes: number
      }>(getHardhatAPIURL(this.chainId))

      this.hardhatNodeForkBlockNumber = r.data.forkBlockNumber
    }
  }
}
