import { One } from '@ethersproject/constants'
import type { BigNumber, Overrides } from 'ethers'
import { inject, injectable, optional } from 'inversify'
import type { GasPriceInversifyService } from './gas-price.inversify.service'
import type { ChainId } from './types/config-types'
import { isHardhatChainId } from './types/config-types'

@injectable()
export class OverridesInversifyService {
  private readonly overrides: Overrides = {}

  constructor(
    @inject('ChainId') readonly chainId: ChainId,
    @inject('GasPriceInversifyService') @optional() public readonly gasPriceService?: GasPriceInversifyService,
  ) {}

  public get = async (overrides?: Overrides): Promise<Overrides> => {
    const runningOverrides = this.overrides
    if (this.gasPriceService) {
      Object.assign(runningOverrides, await this.gasPriceService.getGas())
    }
    if (isHardhatChainId(this.chainId)) {
      Object.assign(runningOverrides, { gasLimit: 16_000_000 })
    }
    return { ...runningOverrides, ...overrides }
  }

  public getGasOfTransaction = async (
    estimateGas: BigNumber | Promise<BigNumber>,
  ): Promise<{ gasCost: BigNumber; total: BigNumber }> => {
    if (!this.gasPriceService) {
      const gasCost = await estimateGas
      return { gasCost, total: gasCost }
    }
    const [cost, { gasPrice, maxPriorityFeePerGas }] = await Promise.all([estimateGas, this.gasPriceService.getGas()])
    const total = gasPrice ? cost.mul(gasPrice) : cost.mul(maxPriorityFeePerGas ?? One)
    return { gasCost: cost, total }
  }
}
