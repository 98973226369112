import { inject, injectable, optional } from 'inversify'
import type { JsonRpcProvider } from '@ethersproject/providers'
import type { HardhatCommandsInversifyService } from './hardhat-commands.inversify.service'
import type { LatestBlockInfoInversifyService } from '../latest-block-info-events.inversify.service'
import type { ThetaVaultInversifyService } from '../theta-vault'
import type { VtReBaserInversifyService } from '../volatility-token/vt.rebaser.inversify.service'

@injectable()
export class HardhatAdvanceTimeInversifyService {
  constructor(
    @inject('EthersJsonRpcBatchProvider') public readonly provider: JsonRpcProvider,
    @inject('EthersJsonRpcBatchProvider') public readonly ethersJsonRpcBatchProvider: JsonRpcProvider,
    @inject('HardhatCommandsInversifyService') private readonly hardhatCommandsService: HardhatCommandsInversifyService,
    @inject('LatestBlockInfoInversifyService')
    private readonly latestBlockInfoInversifyService: LatestBlockInfoInversifyService,
    @inject('ThetaVaultInversifyService')
    @optional()
    private readonly thetaVaultInversifyService?: ThetaVaultInversifyService,
    @inject('VtReBaserInversifyService')
    @optional()
    private readonly reaserInversifyService?: VtReBaserInversifyService,
  ) {}

  public async simpleAdvanceTime(seconds: number): Promise<number> {
    const beforeBlock = await this.latestBlockInfoInversifyService.getCurrentBlock()

    await this.hardhatCommandsService.setTimestampCommand(beforeBlock.timestamp + seconds)

    const afterBlock = await this.latestBlockInfoInversifyService.getAndEmitCurrentBlockWithoutCache()
    return afterBlock.timestamp - beforeBlock.timestamp
  }

  public async advanceTime(seconds: number): Promise<number> {
    const beforeBlock = await this.latestBlockInfoInversifyService.getCurrentBlock()

    await this.setTime(beforeBlock.timestamp + seconds)

    const afterBlock = await this.latestBlockInfoInversifyService.getAndEmitCurrentBlockWithoutCache()
    return afterBlock.timestamp - beforeBlock.timestamp
  }

  public async setTime(timestamp: number): Promise<number> {
    const beforeBlock = await this.latestBlockInfoInversifyService.getCurrentBlock()

    if (this.thetaVaultInversifyService && this.reaserInversifyService) {
      await this.smartAdvanceTimeWithMultipleRebalances({
        secondsToAdvance: timestamp - beforeBlock.timestamp,
        thetaVaultInversifyService: this.thetaVaultInversifyService,
        reaserInversifyService: this.reaserInversifyService,
      })
    } else {
      await this.hardhatCommandsService.setTimestampCommand(timestamp)
    }

    const afterBlock = await this.latestBlockInfoInversifyService.getAndEmitCurrentBlockWithoutCache()
    return afterBlock.timestamp - beforeBlock.timestamp
  }

  private async smartAdvanceTimeWithMultipleRebalances({
    secondsToAdvance,
    secondsInEachStep = 60 * 60 * 24 * 14,
    thetaVaultInversifyService,
    reaserInversifyService,
  }: {
    secondsToAdvance: number
    secondsInEachStep?: number
    thetaVaultInversifyService: ThetaVaultInversifyService
    reaserInversifyService: VtReBaserInversifyService
  }) {
    const owner = await thetaVaultInversifyService.cviContractsInversifyService.thetaVault.owner()
    await this.hardhatCommandsService.impersonateAccountCommand(owner)
    const ownerImpersonatedSigner = this.provider.getSigner(owner)

    const getTimestampNow = () => this.latestBlockInfoInversifyService.getCurrentBlock().then(r => r.timestamp)
    let currentTimestamp = await getTimestampNow()
    const desiredTimestamp = currentTimestamp + secondsToAdvance
    while (currentTimestamp < desiredTimestamp) {
      const nextTimestamp = Math.min(currentTimestamp + secondsInEachStep, desiredTimestamp)
      await this.hardhatCommandsService.setTimestampCommand(nextTimestamp)
      await reaserInversifyService.upkeep()
      await thetaVaultInversifyService.rebalance(ownerImpersonatedSigner)
      currentTimestamp = await getTimestampNow()
    }
  }
}
