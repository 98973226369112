import type { BigNumber } from 'ethers'
import { inject, injectable } from 'inversify'
import type { JsonRpcProvider } from '@ethersproject/providers'
import { JsonRpcBatchProvider } from '@ethersproject/providers'
import type { ChainId } from './types'
import { CHAIN_IDS_INFO } from './types'

@injectable()
export class ProviderInversifyService {
  constructor(
    @inject('ChainId') public readonly chainId: ChainId,
    @inject('EthersJsonRpcBatchProvider') public readonly provider: JsonRpcProvider,
  ) {}

  public getBalance(account: string): Promise<BigNumber> {
    return this.provider.getBalance(account)
  }

  public createExternalProvider(): JsonRpcProvider {
    return new JsonRpcBatchProvider(CHAIN_IDS_INFO[this.chainId].externalRpcUrl)
  }
}
